import { addCollection } from "@iconify/vue"
let _initialized = false
export function init() {
  if (_initialized)
    return
  const collections = [
  {
    "prefix": "majesticons",
    "icons": {
      "beach-line": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M17.696 3.866C15.098 2.366 11.5 2.598 10 5.196l3.464 2m4.232-3.33c2.598 1.5 4.196 4.732 2.696 7.33l-5.196-3m2.5-4.33l.5-.866m-.5.866c-1.821.488-2.982 1.165-4.232 3.33m4.232-3.33c.488 1.821.482 3.165-.768 5.33m-1.732-1l-1.732-1m1.732 1l-3 5.196M3 21l.88-1.056a2 2 0 0 1 3.139.08v0a2 2 0 0 0 3.107.118l.19-.218a2.236 2.236 0 0 1 3.367 0l.191.218c.838.957 2.344.9 3.107-.117v0a2 2 0 0 1 3.14-.08L21 21M6.708 16A7.97 7.97 0 0 1 12 14a7.97 7.97 0 0 1 5.292 2\"/>"
      },
      "ship": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2h3a1 1 0 0 1 .981 1.192l-.437 2.238l-1.327-.295l-5-1.111a1 1 0 0 0-.434 0l-5 1.11l-1.327.296l-.437-2.238A1 1 0 0 1 6 5h3zm-6.092 7.996l-.125.028a1 1 0 0 0-.677 1.423l2 4a1 1 0 0 0 1.035.543L12 16.01l6.859.98a1 1 0 0 0 1.035-.543l2-4a1 1 0 0 0-.677-1.423l-.125-.028a1 1 0 0 1-.309-.02l-4-.889L12 9.024l-4.783 1.063l-4 .89a1 1 0 0 1-.309.019m6.36 7.609a3.63 3.63 0 0 1 5.465 0l.035.04a1.57 1.57 0 0 0 2.053.273a3.57 3.57 0 0 1 3.305-.344l1.245.497a1 1 0 0 1-.742 1.857l-1.245-.498a1.57 1.57 0 0 0-1.454.152a3.57 3.57 0 0 1-4.667-.62l-.035-.04a1.63 1.63 0 0 0-2.456 0l-.035.04a3.57 3.57 0 0 1-4.667.62a1.57 1.57 0 0 0-1.454-.152l-1.245.498a1 1 0 1 1-.742-1.857l1.245-.497a3.57 3.57 0 0 1 3.305.344a1.57 1.57 0 0 0 2.053-.273l.035-.04z\" clip-rule=\"evenodd\"/>"
      },
      "ship-line": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"m19 16l2-4l-4-.889M12 10v5m0-5l-5 1.111M12 10l5 1.111M5 16l-2-4l4-.889m0 0L6 6h4m7 5.111L18 6h-4m0 0V3h-4v3m4 0h-4M3 20l1.245-.498a2.57 2.57 0 0 1 2.38.248v0a2.57 2.57 0 0 0 3.36-.446l.035-.04a2.63 2.63 0 0 1 3.96 0l.036.04a2.57 2.57 0 0 0 3.36.446v0a2.57 2.57 0 0 1 2.38-.248L21 20\"/>"
      }
    }
  },
  {
    "prefix": "ion",
    "icons": {
      "logo-whatsapp": {
        "width": 512,
        "height": 512,
        "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M414.73 97.1A222.14 222.14 0 0 0 256.94 32C134 32 33.92 131.58 33.87 254a220.6 220.6 0 0 0 29.78 111L32 480l118.25-30.87a223.6 223.6 0 0 0 106.6 27h.09c122.93 0 223-99.59 223.06-222A220.18 220.18 0 0 0 414.73 97.1M256.94 438.66h-.08a185.75 185.75 0 0 1-94.36-25.72l-6.77-4l-70.17 18.32l18.73-68.09l-4.41-7A183.46 183.46 0 0 1 71.53 254c0-101.73 83.21-184.5 185.48-184.5a185 185 0 0 1 185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52m101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78s-14.4 18-17.65 21.75s-6.5 4.16-12.07 1.38s-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26s.93-6.94-.46-9.71s-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52c-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 0 0-14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38s39.3 59.73 95.21 83.76a323 323 0 0 0 31.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2c10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37s-5.11-3.71-10.69-6.48\"/>"
      }
    }
  },
  {
    "prefix": "material-symbols",
    "icons": {
      "check-circle": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22\"/>"
      },
      "check-circle-outline-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8\"/>"
      },
      "globe": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12q0-.175-.012-.363t-.013-.312q-.125.725-.675 1.2T18 13h-2q-.825 0-1.412-.587T14 11v-1h-4V8q0-.825.588-1.412T12 6h1q0-.575.313-1.012t.762-.713q-.5-.125-1.012-.2T12 4Q8.65 4 6.325 6.325T4 12h5q1.65 0 2.825 1.175T13 16v1h-3v2.75q.5.125.988.188T12 20\"/>"
      },
      "location-city": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M3 21V7h6V5l3-3l3 3v6h6v10zm2-2h2v-2H5zm0-4h2v-2H5zm0-4h2V9H5zm6 8h2v-2h-2zm0-4h2v-2h-2zm0-4h2V9h-2zm0-4h2V5h-2zm6 12h2v-2h-2zm0-4h2v-2h-2z\"/>"
      },
      "location-city-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M3 19V9q0-.825.588-1.412T5 7h4V5.825q0-.4.15-.763t.425-.637l1-1Q11.15 2.85 12 2.85t1.425.575l1 1q.275.275.425.638t.15.762V11h4q.825 0 1.413.588T21 13v6q0 .825-.587 1.413T19 21H5q-.825 0-1.412-.587T3 19m2 0h2v-2H5zm0-4h2v-2H5zm0-4h2V9H5zm6 8h2v-2h-2zm0-4h2v-2h-2zm0-4h2V9h-2zm0-4h2V5h-2zm6 12h2v-2h-2zm0-4h2v-2h-2z\"/>"
      },
      "security-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M12 19.9q2.425-.75 4.05-2.962T17.95 12H12V4.125l-6 2.25v5.175q0 .175.05.45H12zm0 2q-.175 0-.325-.025t-.3-.075Q8 20.675 6 17.638T4 11.1V6.375q0-.625.363-1.125t.937-.725l6-2.25q.35-.125.7-.125t.7.125l6 2.25q.575.225.938.725T20 6.375V11.1q0 3.5-2 6.538T12.625 21.8q-.15.05-.3.075T12 21.9\"/>"
      },
      "shield-person-outline-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M12 13q-1.475 0-2.488-1.012T8.5 9.5t1.013-2.488T12 6t2.488 1.013T15.5 9.5t-1.012 2.488T12 13m0-2q.65 0 1.075-.425T13.5 9.5t-.425-1.075T12 8t-1.075.425T10.5 9.5t.425 1.075T12 11m0-6.875l-6 2.25V11.1q0 1.35.375 2.625t1.025 2.4q1.05-.525 2.2-.825T12 15t2.4.3t2.2.825q.65-1.125 1.025-2.4T18 11.1V6.375zM12 17q-.9 0-1.75.2t-1.625.55q.725.75 1.575 1.3t1.8.85q.95-.3 1.8-.85t1.575-1.3q-.775-.35-1.625-.55T12 17m0 4.9q-.175 0-.325-.025t-.3-.075Q8 20.675 6 17.637T4 11.1V6.375q0-.625.363-1.125t.937-.725l6-2.25q.35-.125.7-.125t.7.125l6 2.25q.575.225.938.725T20 6.375V11.1q0 3.5-2 6.538T12.625 21.8q-.15.05-.3.075T12 21.9\"/>"
      },
      "shield-spark-outline-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M12 17q0-2.1 1.45-3.55T17 12q-2.1 0-3.55-1.45T12 7q0 2.1-1.45 3.55T7 12q2.1 0 3.55 1.45T12 17Zm0 4.9q-.175 0-.325-.025t-.3-.075Q8 20.675 6 17.637T4 11.1V6.375q0-.625.363-1.125t.937-.725l6-2.25q.35-.125.7-.125t.7.125l6 2.25q.575.225.938.725T20 6.375V11.1q0 3.5-2 6.538T12.625 21.8q-.15.05-.3.075T12 21.9Zm0-2q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25l-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3Zm0-7.9Z\"/>",
        "hidden": true
      }
    }
  },
  {
    "prefix": "material-symbols-light",
    "icons": {
      "favorite": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"m12 19.654l-.758-.685q-2.448-2.236-4.05-3.828q-1.601-1.593-2.528-2.81t-1.296-2.2T3 8.15q0-1.908 1.296-3.204T7.5 3.65q1.32 0 2.475.675T12 6.289Q12.87 5 14.025 4.325T16.5 3.65q1.908 0 3.204 1.296T21 8.15q0 .996-.368 1.98q-.369.986-1.296 2.202t-2.519 2.809q-1.592 1.592-4.06 3.828z\"/>"
      },
      "hotel-class-outline-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"m15.508 14.116l3.473-3l.15.011q.234.028.365.199t.131.368q0 .114-.042.216t-.154.179l-2.754 2.39l.833 3.625q.005.028.005.063v.062q0 .237-.155.39q-.156.152-.383.152q-.071 0-.149-.008t-.145-.065l-.122-.075zM13.6 8.219l-.973-2.296l.052-.127q.066-.17.204-.263t.295-.093t.307.093t.217.257l1.067 2.539zm-6.25 8.606l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4L9.05 10.5l-3.65.325l2.775 2.425zm-1.096.24l.812-3.456l-2.676-2.328q-.142-.131-.214-.286t-.072-.31q0-.286.199-.532q.199-.245.547-.276l3.533-.311L9.748 6.31q.101-.243.313-.364t.434-.121t.438.121t.319.364l1.366 3.256l3.532.311q.348.03.547.276q.2.244.2.53q0 .163-.073.315q-.072.152-.214.283l-2.675 2.328l.818 3.457q.018.055.018.178q0 .347-.24.581q-.242.235-.563.235q-.06 0-.433-.131L10.5 16.102l-3.034 1.827q-.106.056-.217.093t-.212.038q-.364 0-.626-.295t-.157-.7m4.246-4.584\"/>"
      },
      "luggage-outline-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M7.462 20q-.667 0-1.141-.475t-.475-1.14V8q0-.666.475-1.14q.474-.476 1.14-.476h2.155V4.615q0-.666.474-1.14T11.23 3h1.54q.666 0 1.14.475t.475 1.14v1.77h2.154q.666 0 1.14.474q.475.475.475 1.141v10.385q0 .666-.475 1.14T16.54 20v.23q0 .31-.23.54t-.54.23t-.54-.23t-.229-.54V20H9v.23q0 .31-.23.54t-.54.23t-.539-.23t-.23-.54zm0-1h9.077q.269 0 .442-.173t.173-.442V8q0-.27-.173-.442q-.173-.173-.443-.173H7.462q-.27 0-.443.173T6.846 8v10.385q0 .269.173.442t.443.173m2.653-9.846q-.213 0-.356.143t-.143.357v7.077q0 .212.143.356q.144.144.357.144t.356-.144t.144-.356V9.654q0-.213-.145-.356t-.356-.144m3.769 0q-.213 0-.356.144q-.144.143-.144.356v7.077q0 .212.145.356t.356.144t.356-.144t.144-.356V9.654q0-.213-.144-.356q-.144-.144-.357-.144m-3.268-2.77h2.769V4.616q0-.269-.173-.442T12.769 4h-1.538q-.27 0-.442.173q-.173.173-.173.443zM12 13.192\"/>"
      }
    }
  }
]
  for (const collection of collections) {
    addCollection(collection)
  }
  _initialized = true
}